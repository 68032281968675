<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="售后记录" />
			<div class="ct_box">
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker value-format="timestamp" @change="timerFn" v-model="time" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<div class="order_search flex">
							<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
							<el-input class="order_search_inp" v-model="title" placeholder="输入标题/订单号"></el-input>
							<button class="search_but" @click="getList">搜索</button>
						</div>
						<el-select v-model="resource.title" @change="getList" placeholder="请选择">
							<el-option v-for="item in resourceList" :key="item.id" :value="item.title">
							</el-option>
						</el-select>
					</div>
					<ul class="status_ul flex">
						<li :class="status.title == item.title ? 'active' : ''" v-for="(item, index) in statusUl"
							:key="index" @click="statusShow(item)">
							{{ item.title }}
						</li>
					</ul>
				</div>
				<table class="table">
					<thead>
						<tr>
							<td>订单ID</td>
							<td>标题</td>
							<td>问题描述</td>
							<td>媒介名称</td>
							<td>金额</td>
							<td>状态</td>
							<td>提交时间</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="item.id">
							<td>{{ item.sn }}</td>
							<td>{{ item.title }}</td>
							<td>{{ item.desc }}</td>
							<td>{{ item.medium_title }}</td>
							<td>￥{{ item.medium_price }}</td>
							<td>
								<!-- {{item.status == 0 ? '未处理' : '已处理'}} -->
								<div class="untreated" @click="item.check = !item.check" v-if="item.status == 0">
									未处理
								</div>
								<div v-if="item.check" class="untreated_success" @click="upStatus(item, 1)">
									已处理
								</div>
								<div v-if="item.check" class="untreated_refuse" @click="upStatus(item, 2)">
									拒绝处理
								</div>
								<div class="untreated_success" v-if="item.status == 1">
									已处理
								</div>
								<div class="untreated_refuse" v-if="item.status == 2">
									拒绝处理
								</div>
							</td>
							<td>{{ item.updatetime }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</div></template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapMutations } from 'vuex'
import { timeCut } from '../../util/timeCut'
export default {
	components: {
		Title,
	},
	mounted() {
		this.$store.commit('childTab', '售后记录')

		if (this.$route.query.time) {
			this.status = {
				id: 6,
				title: '限时订单',
			}
		} else if (this.$route.query.status && this.$route.query.status == '退稿') {
			this.status = {
				id: 5,
				title: '撤稿',
			}
		} else if (
			this.$route.query.status &&
			this.$route.query.status == '已处理'
		) {
			this.status = {
				id: 2,
				title: '已出稿',
			}
		}
		this.getList()
	},
	data() {
		return {
			time: [],
			resource: {
				id: '',
				title: '不限',
			},
			title: '',
			resourceList: [],
			tableData: [],
			statusUl: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '未处理',
				},
				{
					id: 1,
					title: '已处理',
				},
				{
					id: 2,
					title: '拒绝处理',
				}
			],
			status: {
				id: '',
				title: '全部',
			},
			Link: false, //查看链接弹窗
			order: false, //提交工单弹窗
			orderItem: {}, //工单信息
			serviceShow: false, //客服
			work: '',
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			linkList: {},
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.listFn()
		},
		statusShow(item) {
			this.status = item
			this.getList()
		},
		getList() {
			this.fullscreenLoading = true
			// 取媒体类别
			this.curlGet('/api/medium_category/list', {}).then((res) => {
				if (res.data.code) {
					this.resourceList = res.data.data
				}
			})
			let data = {}
			if (this.status.id !== '') {
				data.status = this.status.id
			}
			if (this.title != '') {
				data.search = this.title
			}
			if (this.time) {
				data.start_time = this.time[0]
				data.end_tiem = this.time[1]
			}
			this.curlGet('/api/users/work/after_sale', data).then((res) => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					this.tableData.forEach((item, index) => {
						if (item.status == 0) {
							this.$set(item, 'check', false)
						}

						/*-------------时间------------------------- */
						item.updatetime = timeCut(item.updatetime * 1000)

					})
					console.log(this.tableData)
					this.fullscreenLoading = false
				}
			})
		},
		// 切换页
		currentTab(val) {
			console.log('切换')
			this.page = val
			this.getList()
		},
		// 上一页
		prevClick() {
			console.log('up')
			this.page -= 1
			this.getList()
		},
		// 下一页
		// next-click
		nextClick() {
			console.log('down')
			this.page += 1
			this.getList()
		},
		// 选中日期后的回调
		timerFn() {
			this.getList()
		},
		// 修改订单状态
		upStatus(item, status) {
			this.curlPost('/api/users/work/status_edit', {
				id: item.id,
				status: status,
			}).then((res) => {
				console.log(res)
				if (res.data.code) {
					this.getList()
					return this.$message({
						message: '修改成功',
						type: 'success',
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/AfterSale';
</style>